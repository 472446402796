import _global2 from "es5-ext/global";
import _version from "./version";
var exports = {};

var _globalThis;

if (typeof globalThis === "object") {
  _globalThis = globalThis;
} else {
  try {
    _globalThis = _global2;
  } catch (error) {} finally {
    if (!_globalThis && typeof window !== "undefined") {
      _globalThis = window;
    }

    if (!_globalThis) {
      throw new Error("Could not determine global this");
    }
  }
}

var NativeWebSocket = _globalThis.WebSocket || _globalThis.MozWebSocket;
var websocket_version = _version;
/**
 * Expose a W3C WebSocket class with just one or two arguments.
 */

function W3CWebSocket(uri, protocols) {
  var native_instance;

  if (protocols) {
    native_instance = new NativeWebSocket(uri, protocols);
  } else {
    native_instance = new NativeWebSocket(uri);
  }
  /**
   * 'native_instance' is an instance of nativeWebSocket (the browser's WebSocket
   * class). Since it is an Object it will be returned as it is when creating an
   * instance of W3CWebSocket via 'new W3CWebSocket()'.
   *
   * ECMAScript 5: http://bclary.com/2004/11/07/#a-13.2.2
   */


  return native_instance;
}

if (NativeWebSocket) {
  ["CONNECTING", "OPEN", "CLOSING", "CLOSED"].forEach(function (prop) {
    Object.defineProperty(W3CWebSocket, prop, {
      get: function () {
        return NativeWebSocket[prop];
      }
    });
  });
}
/**
 * Module exports.
 */


exports = {
  "w3cwebsocket": NativeWebSocket ? W3CWebSocket : null,
  "version": websocket_version
};
export default exports;
export const w3cwebsocket = exports.w3cwebsocket;